import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['submit', 'timer']

  start = Date.now()
  initialSecondsDelay = 20

  connect() {
    this.tickTimer()
  }

  getRemainingTime() {
    return Math.round(
      this.initialSecondsDelay - (Date.now() - this.start) / 1000
    )
  }

  tickTimer() {
    let remainingTime = this.getRemainingTime()

    if (this.timerTarget && remainingTime > 0) {
      this.timerTarget.innerHTML = `Send again in ${remainingTime} seconds.`

      setTimeout(() => this.tickTimer(), 1000)
    } else {
      this.submitTarget.removeAttribute('disabled')
      this.timerTarget.classList.add('hidden')
    }
  }
}
