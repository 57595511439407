import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['root', 'content', 'icon']

  toggle() {
    let isOpen = this.contentTarget.classList.contains('hidden')

    if (isOpen) {
      this.rootTarget.classList.add('bg-gray-100', 'border-gray-100')
      this.rootTarget.classList.remove('border-gray-200')
      this.contentTarget.classList.remove('hidden')
      this.iconTarget.classList.add('rotate-180')
    } else {
      this.rootTarget.classList.remove('bg-gray-100', 'border-gray-100')
      this.rootTarget.classList.add('border-gray-200')
      this.contentTarget.classList.add('hidden')
      this.iconTarget.classList.remove('rotate-180')
    }
  }
}
