import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['submitAction', 'field']

  handleInputChange() {
    this.submitActionTarget.disabled = this.fieldTargets.some(
      field => field.value.length === 0
    )
  }
}
