import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    if (this.element.selectedIndex === 0) {
      this.element.classList.add('text-black-opacity-50')
    }
  }

  log() {
    if (this.element.selectedIndex === 0) {
      this.element.classList.add('text-black-opacity-50')
    } else {
      this.element.classList.remove('text-black-opacity-50')
    }
  }
}
