import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['root']

  connect() {
    if (localStorage.getItem('profile_notification') == null) {
      this.rootTarget.classList.remove('hidden')
    }
  }

  handleCloseClick() {
    localStorage.setItem('profile_notification', 'disabled')
    this.rootTarget.classList.add('hidden')
  }
}
