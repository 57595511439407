import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['otherTextField']

  handleOtherOptionActivity() {
    if (this.otherTextFieldTarget.disabled) {
      this.otherTextFieldTarget.classList.remove('hidden')
      this.otherTextFieldTarget.disabled = false
      this.otherTextFieldTarget.focus()
    } else {
      this.otherTextFieldTarget.classList.add('hidden')
      this.otherTextFieldTarget.disabled = true
      this.otherTextFieldTarget.value = ''
    }
  }
}
