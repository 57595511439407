import { Controller } from '@hotwired/stimulus'
import TomSelect from 'tom-select'
import 'styles/tom-select.css'

export default class extends Controller {
  connect() {
    this.control = new TomSelect(this.element.querySelector('select'), {
      create: false
    })
  }

  disconnect() {
    this.control.destroy()
  }
}
