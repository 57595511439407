import { Controller } from '@hotwired/stimulus'
import { appendScript, enableAnalytics } from 'utils/ga'

export default class extends Controller {
  static targets = ['root']

  connect() {
    if (localStorage.getItem('analytics') == null) {
      this.rootTarget.classList.remove('hidden')
    }
  }

  handleDeclineClick() {
    localStorage.setItem('analytics', 'disabled')
    this.rootTarget.classList.add('hidden')
  }

  handleAcceptClick() {
    localStorage.setItem('analytics', 'enabled')
    this.rootTarget.classList.add('hidden')
    appendScript()
    enableAnalytics()
  }
}
