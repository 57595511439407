import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['field', 'submitAction', 'consent']

  handleInputChange() {
    this.submitActionTarget.disabled =
      this.fieldTargets.some(field => field.value.length === 0) ||
      !this.consentTarget.checked
  }
}
