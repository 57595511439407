/* eslint-disable @typescript-eslint/no-explicit-any */

declare global {
  interface Window {
    dataLayer: any
    gtag: any
  }
}

const isProduction = import.meta.env.MODE === 'production'

export const appendScript = (): void => {
  let script = document.createElement('script')
  script.setAttribute(
    'src',
    'https://www.googletagmanager.com/gtag/js?id=G-02JC2DDL1W'
  )
  script.async = true
  document.head.appendChild(script)
}

export const enableAnalytics = (): void => {
  window.dataLayer = window.dataLayer || []
  window.gtag = function () {
    if (isProduction) {
      // eslint-disable-next-line prefer-rest-params
      window.dataLayer.push(arguments)
    } else {
      // eslint-disable-next-line prefer-rest-params, no-console
      console.log('gtag event:', ...arguments)
    }
  }

  window.gtag('js', new Date())
  window.gtag('config', 'G-DQXW0613E3')
}

export interface EventPayload {
  event_category?: string
  event_label?: string
  value?: number
}

export const sendEvent = (name: string, payload: EventPayload = {}): void => {
  window.gtag('event', name, payload)
}

if (localStorage.getItem('analytics') === 'enabled') {
  if (isProduction) {
    appendScript()
  }
}

enableAnalytics()
